<template>
  <div class="wrapper-container">
    <h2 class="header-2">Design your avatar</h2>
    <p class="desc-text"></p>
    <h3 class="choose-text">Choose a base</h3>
    <section class="bases">
      <div
        v-for="base in bases"
        :key="base.id"
        class="avatar-highlight"
        :class="{ 'highlight-active': selectedBase === base.id }"
        @click="highlightBase(base.id)"
      >
        <AvatarCircle
          size="190"
          :avatar="store.state.avatar"
          backgroundColor="#FFFFFF"
          :base="base"
          shirtColor="#E4E7E5"
          skinTone="#F7D8D0"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
// import { defineProps, defineEmits, computed } from 'vue'
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
import AvatarCircle from "@/components/AvatarCircle";
import bases from "@/data/avatar/avatarBases";
import { avatarNames } from "@/store/modules/avatar";
const props = defineProps({
  tab: {
    required: true,
  },
});
console.log("%%%%", props.tab);
const store = useStore();

const selectedBase = computed({
  get: () => store.state.avatar.base,
  set: (value) => store.commit(avatarNames.setBase, value),
});
// const emit = defineEmits(['baseSelected'])

const highlightBase = (id) => {
  // emit('selectedBase', true)
  selectedBase.value = id;
};
</script>

<style scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-2 {
  margin: 0 0 10px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 36px;
  color: black;
}

.desc-text {
  width: 310px;
  text-align: center;
}

.choose-text {
  margin-top: 40px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 18px;
}

.bases {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 35px;
  margin: 30px 50px 140px;
}

.avatar-highlight {
  border-radius: 50%;
  cursor: pointer;
}

.highlight-active {
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 18px rgba(255, 130, 23, 0.65);
}
</style>
